<template lang="pug">
  div.d-flex.flex-column
      HeaderGrid(:name="name" :uid="uid" userFilter="missionFilters" :dateRanger="dateRanger" :filterCollaborators="filterCollaborators"  :dateRangeDefault="dateRangeDefault" :tvaModeButton="tvaModeButton" :addButton="addButton" :addButton2="addButton2" :addButton2Icon="addButton2Icon" :searchButton="searchButton" :tagguerButton="tagguerButton" :selectedRows="selectedRows" @setDates="setDates" @onFilterTextBoxChanged="onFilterTextBoxChanged" @selectParamerterAction="setSelectParamerterAction" @clickAction="clickAction" :searchPlaceholder="searchPlaceholder" :groupActionButton="groupActionButton" :groupActionButtonLabel="groupActionButtonLabel" @refreshRow="refreshRow" @setCollaborators="setCollaborators" :indexSearch="indexSearch")
      StatCards
            div.cursor-pointer.pl-1.col-card-list-stats(@click="changeTab('all')")
              .card-list-stats(:class="tabActive == 'all' ? 'current' : ''")
                  .card-list-stats-title
                      div
                          span.card-list-stats-title-badge.material-icons.text-primary.mr-50 list
                          span.card-list-stats-title-text TOUT
                      .card-list-stats-title-badge.bg-primary.text-white
                        span {{ getCountDataByStatus("all") }}
                  .card-sum
                      | {{ getSumDataByStatus("all") }} 
                      span Heures
            div.cursor-pointer.pl-1.col-card-list-stats(v-for="(status, index) of statusList" :key="index" @click="changeTab(status.value)")
              .card-list-stats(:class="[tabActive == status.value ? 'current' : '', 'border-'+status.class]")
                  .card-list-stats-title
                      div
                          span.card-list-stats-title-badge.material-icons.mr-25(:class="'text-'+status.class") {{ status.icon }}
                          span.card-list-stats-title-text {{ status.label }}
                      .card-list-stats-title-badge.text-white(:class="'bg-'+status.class")
                        span {{ getCountDataByStatus(status.value) }}
                  .card-sum
                      | {{ getSumDataByStatus(status.value) }} 
                      span Heures
      .d-flex.w-100.h-100(style="overflow: hidden;")
          .text-center.flex-center.w-100(v-if='isLoadingReportDetailsList || !headerLoaded || !dataLoaded')
              .loading-bg-inner
                  .loader
                      .outer
                      .middle
                      .inner
              .mt-5
                  br
                  br
                  br
                  | Chargement de la liste des missions...
          sync-grid#overviewgrid(ref="overviewgrid" v-else :uid="uid" :gridData="dataFiltered" :name="name" :headerData="headerData" :selectionSettings="selectionSettings" :editSettings="editSettings" :allowGrouping="allowGrouping" @setSelectedRows="setSelectedRows" :tagguerButton="tagguerButton" :allowKeyboard="allowKeyboard" @actionBegin="actionBegin")
  </template>

<script>
import { mapGetters, mapActions } from "vuex";
import { reportingStatus } from "@/types/api-orisis/enums/enums";
import StatCards from "@/components/global/grid/StatCards.vue";

import { formatNumber } from "@/types/api-orisis/library/FormatOperations.ts";
import { formatCurrency } from "@/types/api-orisis/library/FormatOperations.ts";
import { setColumnsByObject } from "@/types/api-orisis/library/ObjectOperations.ts";

var dayjs = require("dayjs");
var quarterOfYear = require("dayjs/plugin/quarterOfYear");
var utc = require("dayjs/plugin/utc");
var timezone = require("dayjs/plugin/timezone");
var advanced = require("dayjs/plugin/advancedFormat");
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault("Europe/Paris");
dayjs.extend(advanced);
dayjs.extend(quarterOfYear);

import SyncGrid from "@/components/global/grid/Grid.vue";
import HeaderGrid from "@/components/global/grid/HeaderGrid.vue";

export default {
  components: {
    SyncGrid,
    HeaderGrid,
    StatCards,
  },
  data() {
    return {
      dateRangeDefault: [
        new Date(
          dayjs().subtract(3, "month").startOf("month").format("YYYY-MM-DD")
        ).toISOString(),
        new Date(dayjs().endOf("month").format("YYYY-MM-DD")).toISOString(),
      ],
      indexSearch: 0,
      name: "missions",
      uid: "missions",
      statusList: reportingStatus.filter((el) => !el.notUse),
      headerData: [],
      headerLoaded: false,
      tabActive: "all",
      selectedRows: [],
      collaboratorIds: [],
      dataLoaded: false,
      dateRanger: true,
      filterCollaborators: true,
      tvaModeButton: false,
      addButton: false,
      addButton2: false,
      addButton2Icon: "e-icons e-people",
      searchButton: true,
      tagguerButton: false,
      groupActionButton: false,
      groupActionButtonLabel: "Changer le statut",
      searchPlaceholder: "Rechercher une mission",
      allowKeyboard: false,
      allowGrouping: true,
      editSettings: {
        allowEditing: false,
        allowAdding: false,
        allowDeleting: false,
        mode: "Normal",
        titleAdd: "Nouvelle mission",
        titleEdit: "Modifier la mission",
        titleField: "collaboratorFullName",
      },
      selectionSettings: {
        type: "Multiple",
        enableSimpleMultiRowSelection: false,
      },

      newReportType: "single",
    };
  },
  computed: {
    ...mapGetters([
      "collaboratorsList",
      "reportDetailsList",
      "isLoadingReportDetailsList",
      "collaboratorsList",
      "affairsList",
      "missionsList",
      "workspaceSelected",
      "userParametersList",
    ]),
    missionsListFiltered() {
      return this.$store.getters["missionsList"].map((elem) => {
        return {
          ...elem,
          parentId: elem.parentId == 0 ? null : elem.parentId,
          hasChild: this.missionsList.filter(
            (child) => child.parentId == elem.id && child.id !== elem.id
          )
            ? true
            : false,
        };
      });
    },
    dataOrigine() {
      return this.$store.getters["reportDetailsList"];
    },
    dataFiltered() {
      let filteredData = this.dataOrigine.filter(detail => detail.affairId != null);

      if (this.statusList) {
        if (this.tabActive == "all") {
          if (this.collaboratorIds.length > 0) {
            return filteredData
              .filter((elem) =>
                this.collaboratorIds.includes(elem.collaboratorId)
              )
              .map((el) => {
                return {
                  ...el,
                  collaboratorFullName: this.collaboratorsList.find(
                    (collab) => el.collaboratorId == collab.id
                  )
                    ? this.collaboratorsList.find(
                        (collab) => el.collaboratorId == collab.id
                      ).firstName +
                      " " +
                      this.collaboratorsList.find(
                        (collab) => el.collaboratorId == collab.id
                      ).lastName
                    : "Non affecté",
                };
              });
          } else {
            return filteredData.map((el) => {
              return {
                ...el,
                collaboratorFullName: this.collaboratorsList.find(
                  (collab) => el.collaboratorId == collab.id
                )
                  ? this.collaboratorsList.find(
                      (collab) => el.collaboratorId == collab.id
                    ).firstName +
                    " " +
                    this.collaboratorsList.find(
                      (collab) => el.collaboratorId == collab.id
                    ).lastName
                  : "Non affecté",
              };
            });
          }
        } else {
          let statusInfos = this.statusList.find(
            (elem) => elem.value == this.tabActive
          );
          if (this.collaboratorIds.length > 0) {
            return filteredData
              .filter(
                (elem) =>
                  (statusInfos.query
                    ? statusInfos.query.includes(elem.reportStatus)
                    : elem.reportStatus === this.tabActive) &&
                  this.collaboratorIds.includes(elem.collaboratorId)
              )
              .map((el) => {
                return {
                  ...el,
                  collaboratorFullName: this.collaboratorsList.find(
                    (collab) => el.collaboratorId == collab.id
                  )
                    ? this.collaboratorsList.find(
                        (collab) => el.collaboratorId == collab.id
                      ).firstName +
                      " " +
                      this.collaboratorsList.find(
                        (collab) => el.collaboratorId == collab.id
                      ).lastName
                    : "Non affecté",
                };
              });
          } else {
            return filteredData
              .filter((elem) =>
                statusInfos.query
                  ? statusInfos.query.includes(elem.reportStatus)
                  : elem.reportStatus === this.tabActive
              )
              .map((el) => {
                return {
                  ...el,
                  collaboratorFullName: this.collaboratorsList.find(
                    (collab) => el.collaboratorId == collab.id
                  )
                    ? this.collaboratorsList.find(
                        (collab) => el.collaboratorId == collab.id
                      ).firstName +
                      " " +
                      this.collaboratorsList.find(
                        (collab) => el.collaboratorId == collab.id
                      ).lastName
                    : "Non affecté",
                };
              });
          }
        }
      } else {
        return [];
      }
    },
  },
  async created() {
    Promise.all([
      this.getMissions({}),
      this.getAffairs({}),
      this.getCollaborators({}),
    ]).then(async (res) => {
      this.dataLoaded = true;
    });
  },
  async activated() {
    let filter = this.userParametersList.find(
      (el) => el.key == "missionFilters"
    );
    if (filter) {
      if (JSON.parse(filter.value).dateRange) {
        this.setDates(JSON.parse(filter.value).dateRange);
      } else {
        this.setDates({
          startDate: dayjs().startOf("year").format("YYYY-MM-DD"),
          endDate: dayjs().endOf("year").format("YYYY-MM-DD"),
        });
      }
    } else {
      await this.setDates({
        startDate: dayjs()
          .subtract(3, "month")
          .startOf("month")
          .format("YYYY-MM-DD"),
        endDate: dayjs().endOf("month").format("YYYY-MM-DD"),
      });
    }
  },
  methods: {
    ...mapActions([
      "getReportDetails",
      "getMissions",
      "getCollaborators",
      "getAffairs",
      "deleteReport",
    ]),
    formatNumber,
    formatCurrency,
    setColumnsByObject,

    setHeaderData() {
      this.headerData = [
        {
          type: "date",
          format: "formatDateOptions",
          field: "reportDate",
          headerText: "Date",
          width: 95,
          maxWidth: 95,
          template: "dateYmdTemplate",
          allowFiltering: true,
          allowSorting: true,
          allowEditing: false,
          showInColumnChooser: true,
          visible: true,
        },
        {
          type: "number",
          field: "collaboratorId",
          headerText: "Collaborateur",
          width: 200,
          minWidth: 200,
          allowEditing: true,
          allowFiltering: true,
          allowSorting: true,
          showInColumnChooser: true,
          filterTemplate: "collaboratorFilterTemplate",
          valueAccessor: "dropdown",
          foreignKeyValue: "id",
          dataSource: this.collaboratorsList,
          edit: {
            source: this.collaboratorsList,
            fields: { text: "displayLabel", value: "id" },
            type: "dropdown", //<<FIX
          },
          // editType: "dropdownedit",
          // template: 'collaboratorDisplayLabelTemplate',
          visible: true,
        },
        {
          type: "number",
          field: "affairId",
          filterTemplate: "affairFilterTemplate",
          headerText: "Affaire",
          width: 200,
          minWidth: 200,
          allowFiltering: true,
          allowSorting: true,
          allowEditing: true,
          showInColumnChooser: true,
          visible: true,
          valueAccessor: "dropdown",
          //- template: "affairTemplate",
          edit: {
            source: this.affairsList,
            fields: { text: "displayLabel", value: "id" },
            type: "dropdowndependencies",
            name: "dropdownaffair",
            target: "dropdownmission",
          },
        },
        {
          type: "number",
          field: "missionId",
          filterTemplate: "missionFilterTemplate",
          headerText: "Tâche",
          width: 200,
          minWidth: 200,
          allowFiltering: true,
          allowSorting: true,
          allowEditing: true,
          showInColumnChooser: true,
          visible: true,
          valueAccessor: "dropdown",
          edit: {
            source: this.missionsListFiltered,
            fields: {
              text: "label",
              value: "id",
              parentValue: "parentId",
              hasChildren: "hasChild",
              expanded: "hasChild",
              dataSource: this.missionsListFiltered,
            },
            type: "dropdowndependencies",
            name: "dropdownmission",
            origine: "dropdownaffair",
          },
          validationRules: { required: true },
        },
        {
          type: "number",
          format: "formatNumber",
          field: "duration",
          headerText: "Durée réelle",
          width: 140,
          maxWidth: 140,
          textAlign: "center",
          showInColumnChooser: true,
          visible: true,
          showFooterSum: true,
          allowFiltering: true,
          allowAggregate: true,
        },
        {
          type: "number",
          format: "formatNumber",
          field: "durationValue",
          headerText: "Durée valorisée",
          width: 140,
          maxWidth: 140,
          textAlign: "center",
          showInColumnChooser: true,
          visible: true,
          showFooterSum: true,
          allowFiltering: true,
          allowAggregate: true,
        },
        {
          type: "string",
          field: "message",
          headerText: "Commentaire",
          width: "auto",
          minWidth: 300,
          showInColumnChooser: true,
          visible: true,
          allowGrouping: false,
          allowFiltering: true,
          clipMode: "EllipsisWithTooltip",
          template: "stripHtmlTemplate",
          filter: { type: "Menu", operator: "contains" },
        },
      ];
    },
    async setDates(dates) {
      if (dates.startDate) {
        let dateRange = {
          startDate: new Date(dates.startDate),
          endDate: dates.endDate ? new Date(dates.endDate) : new Date(),
        };
        this.dateRangeDefault = [dateRange.startDate, dateRange.endDate];
        await this.getReportDetails({
          startDate: dateRange.startDate,
          endDate: dateRange.endDate,
        }).then(async (res) => {
          this.setHeaderData();
          if (res.length) {
            await this.setColumnsByObject(res[0], null, this);
            this.indexSearch++;
          }
          this.headerLoaded = true;
        });
      }
    },
    async changeTab(tab) {
      this.tabActive = tab;
    },
    async actionBegin(args) {
      if (args.requestType === "beginEdit" || args.requestType === "add") {
        for (
          var i = 0;
          i < this.$refs.overviewgrid.$refs.overviewgrid.getColumns().length;
          i++
        ) {
          if (
            this.$refs.overviewgrid.$refs.overviewgrid.getColumns()[i].field ==
            "status"
          ) {
            this.$refs.overviewgrid.$refs.overviewgrid.getColumns()[
              i
            ].visible = false;
          }
        }
      }
      if (args.requestType === "save") {
        for (
          var i = 0;
          i < this.$refs.overviewgrid.$refs.overviewgrid.getColumns().length;
          i++
        ) {
          if (
            this.$refs.overviewgrid.$refs.overviewgrid.getColumns()[i].field ==
            "status"
          ) {
            this.$refs.overviewgrid.$refs.overviewgrid.getColumns()[
              i
            ].visible = true;
          }
        }
      }
    },
    setCollaborators(collaboratorIds) {
      this.collaboratorIds = collaboratorIds;
    },
    onFilterTextBoxChanged(search) {
      this.$refs.overviewgrid.onFilterTextBoxChanged(search);
    },
    clickAction() {
      this.$refs.overviewgrid.$refs.overviewgrid.addRecord();
    },
    setSelectParamerterAction(args) {
      if (["csv", "xls", "print"].includes(args.item.type)) {
        this.$refs.overviewgrid.exportData(args.item.type);
      } else {
        this.$refs.overviewgrid.$refs.overviewgrid.openColumnChooser();
      }
    },
    getCountDataByStatus(status) {
      if (status == "all") {
        return this.dataOrigine.length;
      } else {
        let statusInfos = this.statusList.find((elem) => elem.value == status);
        return this.dataOrigine.filter((elem) =>
          statusInfos.query
            ? statusInfos.query.includes(elem.reportStatus)
            : elem.reportStatus === status
        ).length;
      }
    },
    getSumDataByStatus(status) {
      let sum = 0;
      if (status == "all") {
        this.dataOrigine.forEach((obj) => {
          sum += obj["duration"];
        });
      } else {
        let statusInfos = this.statusList.find((elem) => elem.value == status);
        this.dataOrigine
          .filter((elem) =>
            statusInfos.query
              ? statusInfos.query.includes(elem.reportStatus)
              : elem.reportStatus === status
          )
          .forEach((obj) => {
            sum += obj["duration"];
          });
      }
      return this.formatNumber(sum);
    },
    setSelectedRows(val) {
      this.selectedRows = val;
    },
    refreshRow(val) {
      this.$refs.overviewgrid.$refs.overviewgrid.setRowData(val.id, val);
    },
  },
};
</script>
